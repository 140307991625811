
import { computed, defineComponent, onMounted, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import { statusColor } from "@/core/services/HelpersFun";
import TransactionManagementDetails from "@/views/pages/Admin/transactions/components/TransactionManagementDetails.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import { ElNotification } from "element-plus/es";
import JwtService from "@/core/services/JwtService";
import { useI18n } from "vue-i18n";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import DatePicker from "vue3-persian-datetime-picker";

export default defineComponent({
  name: "kt-transaction-management-table",
  components: {
    TransactionManagementDetails,
    UserAvatar,
    DatePicker,
  },
  props: {
    widgetClasses: String,
  },
  setup() {
    const { t, te } = useI18n();
    const translate = (text) => {
      if (te(text)) return t(text);
      return text;
    };

    const loading = ref(true);
    const list = ref([]);
    const selectedTransaction = ref();
    const page = ref(1);

    const filter = ref({
      from_date: "",
      to_date: "",
      status: "",
      page: page.value,
      search: "",
      package_types: ["paid", "free"],
    });

    const getItems = () => {
      loading.value = true;
      ApiService.query("admin/reports/expiring-bots", { params: filter.value })
        .then(({ data }) => {
          list.value = data.data.expiring_bots.data;
          pagination.value = data.data.expiring_bots.pagination;
        })
        .catch(() => {
          ElNotification({
            title: "خطا",
            message: "خطا در بارگزاری لیست",
            type: "error",
          });
        })
        .finally(() => {
          loading.value = false;
        });
    };
    const excelLink = computed(() => {
      return `${
        process.env.VUE_APP_BACK_URL
      }admin/expiring-bots/download?api_token=${JwtService.getToken()}&package_types=${
        filter.value.package_types
      }&from_date=${filter.value.from_date}&to_date=${filter.value.to_date}`;
    });

    const pagination = ref();

    const handleCurrentChange = (val: number) => {
      filter.value.page = val;
      getItems();
    };

    const permission = ref();
    const userType = ref();

    onMounted(() => {
      setTimeout(() => {
        let per: any = localStorage.getItem("permissions");
        permission.value = JSON.parse(per);
        let user: any = localStorage.getItem("user_info");
        userType.value = JSON.parse(user).type;
      }, 3000);
      setCurrentPageBreadcrumbs("اشتراک‌های های در حال انقضا", []);
    });
    onMounted(() => {
      getItems();
    });

    const filterData = (value) => {
      if (filter.value.package_types.includes(value)) {
        filter.value.package_types = filter.value.package_types.filter(
          (item) => {
            return item !== value;
          }
        );
      } else {
        filter.value.package_types.push(value);
      }
      getItems();
    };

    const resetFilter = () => {
      filter.value.from_date = "";
      filter.value.to_date = "";
      filter.value.package_types = ["paid", "free"];
      getItems();
    };

    return {
      loading,
      list,
      pagination,
      handleCurrentChange,
      page,
      getItems,
      translate,
      statusColor,
      selectedTransaction,
      filter,
      resetFilter,
      filterData,
      excelLink,
      permission,
      userType,
    };
  },
});
